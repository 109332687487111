import styled from 'styled-components';
import tw from 'twin.macro';

export const WrapContent = styled.div`
  ${tw`h-screen sm:h-full overflow-y-scroll sm:overflow-auto`};
  p, li{
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #383C3F;
  }
  li{
    margin: 0;
  }
  .subTitle{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #4D4545;
  }
  @media (max-width: 768px) {
  }
`;

export const Wrap = styled.div`
  ${tw`m-auto`};
  width: 60.666667%;
  h1{
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin-bottom: 32px;
    font-weight: 500;
  }
  a{
    ${tw`text-xssemi text-blue-500 tracking-wide`};
  }
  @media (max-width: 1640px) {
    width:80.666667%;
  }
  @media (max-width: 768px) {
    width:100%;
    padding: 16px;
  }
`;

export const Grid = styled.div`
  padding-bottom:35px;
  ${tw`text-sm`};
  hr{
    background: #C4C4C4;
  }
  p{
    margin: 12px 0px;
  }
  b{
    ${tw`text-sm text-brown-semidark pb-6 mb-3 tracking-wide`};
  }
  ul{
    a{
      ${tw`text-sm text-blue-500 tracking-wide`};
    }
  }
  div{
    margin-bottom: 24px;
  }
  span{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #4D4545;
  }
  .mg-0{
    margin: 0;
  }
`;

export const GridContent = styled.div`
  padding: 49px 0;
  ${tw`grid grid-cols-1 sm:grid-cols-2 gap-8`};
  span{
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #383C3F;
    margin: 4px 0px;
    font-weight: 500;
  }
  p{
    margin: 0 0 12px;
  }
  a{
    ${tw`text-sm`};
  }
  .terms{
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.04em;
    color: #4D4545;
    margin-bottom: 12px;
  }
  .fullTerms{
    margin-top: 64px;
  }
`;

export const Mobile = styled.div`
  @media (min-width: 992px) {
    ${tw`hidden`};
  }
  @media (max-width: 991px) {
    ${tw`block`};
  }
`;
