import React from 'react';
import Container from 'components/ui/Container';
import * as Styled from './styles'
import { I18nextContext } from "gatsby-plugin-react-i18next";
import Back from "../ui/GoBackLink";

const TermsCondition = () => {
  const { language } = React.useContext(I18nextContext);
  return (
    <Container>
      <Styled.WrapContent>
        <Styled.Wrap>
          <Styled.Mobile>
            <Back title={language === 'en' ? 'Go Back' : '回去'} link="/shop" />
          </Styled.Mobile>
          <br/>
          <br/>
          <h1>Terms and Conditions</h1>
        </Styled.Wrap>
        <Styled.Wrap>
          <Styled.Grid>
            <div className='mg-0'>
              <div>
                <span>General T & C</span>

                <p>As a condition of using this website, you agree to comply with and be subject to all of the following terms and conditions of use.</p>

                <p>All prices are in Australian Dollars.</p>

                <p>We take the responsible service of alcohol very seriously. If the customer looks under 25 and is unable to present valid photo identification, your order will be cancelled. If anyone present at the premises at the time of delivery looks under 25 and is not able to present valid photo identification, your order will be cancelled.  If you, the customer, show signs of intoxication either when placing your order or at the time of delivery, your order will be cancelled. Alcohol cannot be left unattended for any reason at any time. We reserve the right to refuse service at our sole discretion, this may be because of our concern for a customer's welfare and our commitment to responsible service in the community.</p>

                <p>Promotional codes, vouchers and credits: Promotional codes and credits cannot be exchanged for cash, used in conjunction with other offers/codes, re-used on another transaction, partially used across multiple orders, or, used for delivery fees (unless specified otherwise).</p>

                <p>Ubottle supports the responsible service of alcohol. It is against the law to sell or supply alcohol to, or to obtain alcohol on behalf of, a person under the age of 18 years. Under New South Wales Liquor Act 2007:No Alcohol can be sold or supplied to anyone under 18. It's against the law.</p>

                <p>Same Day orders in NSW supplied under Liquor Licence LIQP770017656. ABN 41627203141</p>

                <p>Licence conditions imposed by the Liquor Act and Regulation apply.</p>
                <ul className="list-decimal mx-10">
                <li>The licensee must ensure that only the following liquor products are sold or supplied by the licensed business:
                  <ol className="list-disc mx-10">
                    <li>liquor products produced in South Korea, Japan, China, Thailand, Vietnam, Indonesia, India, Taiwan, Singapore, Malaysia and the Philippines;
                      APP-0005907412 – Application for a Packaged liquor licence – Decision by the Independent Liquor and Gaming Authority</li>
                    <li>Sake</li>
                  </ol>
                </li>

                <li>Other complementary liquor products, provided that those other products do not exceed more than 10% of the total product lines or 10% of the total products stocked on the premises at any one time, are also permitted.</li>
                <li>The licensee must ensure that a list of the product lines and products stocked by the Business at any one time is kept at the premises and made available for inspection on the request of a police officer, Liquor & Gaming NSW inspector, or any other person authorised by the Independent Liquor and Gaming Authority.</li>
                <li>For every liquor product that is available for sale, the licensee must maintain and make available for inspection on the premises documentation from the supplier confirming the location of production, and/or that the product meets the relevant definition specified in this condition.</li>
                </ul>
                <p>Following the Liquor Act 2007, Ubottle is tradition under the following condition</p>
                <ul className="list-disc mx-10">
                  <li>Not permitted to trade on Good Friday</li>
                  <li>December 24th Normal trading Monday to Saturday, 8:00 AM to 12:00 midnight on a Sunday</li>
                  <li>Not permitted to trade on Christmas Day</li>
                  <li>December 31st Normal trading Monday to Saturday, 10:00 AM to 12:00 midnight on a Sunday</li>
                </ul>
              </div>

              <div>
                <span>Returns and Refunds Policy</span>
                <p>Please choose carefully as Ubottle does not offer any returns or refunds where you have changed your mind or made a mistake in ordering.</p>

                <p>If a product you have purchased from Ubottle is faulty, damaged (excluding damage caused by misuse), wrongly described or breaches a consumer guarantee, we will cheerfully refund your money.</p>

                <p>For some high value products, Ubottle reserves the right to have the product assessed for acceptable quality prior to discussing a suitable remedy with you.</p>

                <p>Refunds must be made within 5 working days of purchase. We will ask you for a valid proof of purchase when returning a faulty product. Some examples of acceptable proof of purchase are your original receipt, tax invoice, or credit card statement clearly showing your purchase at Ubottle. If your purchase incurred a delivery fee, delivery fee will not be refunded.</p>

                <p>When returning/refunding a product, you may be asked for information relevant to your return or to satisfy legislative requirements. If you do not provide this information, we may be unable to process your return. For example, you may be asked for your signature as authorisation of the return transaction and identification containing your full name.</p>

                <p>Our products come with guarantees that cannot be excluded under the Australian Consumer Law. You are entitled to a replacement or refund for a major failure and compensation for any other reasonably foreseeable loss or damage.</p>
              </div>

              <div>
                <span>Delivery and Pick-Up</span>
                <p>Limiting contact when delivering/picking up orders will help keep everyone healthy, so  the drivers will keep as much distance from you as possible.</p>

                <p>Order online and collect your order from Ubottle when you're ready, your order will be ready to pick up in 1 hour.</p>

                <p>Delivery service available in selected metro areas.
                  Serviced Postcodes:
                  2000 2007 2008 2009 2010 2011 2015 2016 2017 2018 2019 2021 2022 2023 2024 2025 2026 2027 2027 2028 2029 2030 2031 2032 2033 2034 2034 2035 2036 2037 2038 2039 2040 2041 2042 2043 2044 2045 2046 2049 2050 2060 2200 2203 2204 2036 2046 2060 2060 2061 2062 2063 2065 2066 2068 2089 2131 2134 2190 2192 2194 2195 2216 2069 2070 2090 2112 2113 2114 2122 2135 2138,
                  Delivery fee will be calculated upon checkout.</p>
              </div>

              <div>
                <span>Gift Voucher</span>
                <p>Gift Vouchers can be purchased from Ubottle. Pay for your Gift Voucher value with any major credit card or PayPal listed on our shopping cart page. The transaction will be completed when the Gift Voucher is activated and loaded with value. There may be a delay with the Gift Voucher activating in the system once loaded. During this delay, the Gift Voucher cannot be used for purchases.</p>

                <p>Your Gift Voucher may only be used to make purchases at Ubottle.</p>
                <p>Your Gift Voucher may only be used to make purchases up to the initial Gift Voucher value or the Remaining Gift Voucher Value. If you wish to make a purchase for an amount that exceeds the Gift Voucher value or the remaining Gift Card value, you must pay the excess using another payment method.</p>
                <p>No change will be given for any remaining Gift Voucher value. The remaining Gift Voucher value may be used in whole or part against future purchases until the Gift Voucher expires.</p>

                <p>Goods that are purchased solely, or in part with a Gift Voucher may be exchanged or returned subject to Ubottle’s exchange and returns policy.</p>

                <p>Gift Vouchers are not legal tender, account cards, credit or debit cards. You cannot obtain any cash advance with your Gift Voucher, redeem your Gift Voucher for cash or receive any portion of the remaining Gift Voucher value in cash.</p>

                <p>You can check your Gift Voucher balance and expiry date at any time by visiting xxx.com.au. You will be required to login to your user account before you will have access to your Gift Voucher information.</p>

                <p>All Gift Vouchers have an expiry date, any remaining Gift Voucher value after the expiry date will be cancelled.</p>

              </div>

              <div>
                <span>Privacy Policy</span>
                <p>Full Privacy Policy is available here (link to the page, may need legal advice) and forms part of these terms and conditions. You acknowledge and agree to the terms of our Privacy Policy.</p>

                <p>You agree that we may send you commercial electronic messages and tailored advertising (which include marketing communications advertising goods and services) via various channels and media (including by email, SMS, phone and mail) where you have not opted out from receiving such electronic messages sent to you by Ubottle via those channels or media. Your agreement to receive commercial electronic messages from us will be effective until you opt out. You may opt out of the receipt of commercial electronic messages, by using the unsubscribe facility in the footer of any commercial electronic message.</p>

                <p>The information we gather from you helps us to continually improve your experience with Ubottle. This personal information may include: your name and contact information; date of birth; preferred communication methods; bank details and/or credit card details and your objectives and interests. We also create information that becomes part of the personal information we hold about you, such as your membership number and products that you purchase from us.</p>

                <p>Ubottle endeavours to take all steps as are reasonable in the circumstances to protect your personal information from misuse, interference and loss and from unauthorised access, modification or disclosure.</p>

                <p>Our site may include links to other websites. We do not provide any personally identifiable customer information to these advertisers or third-party websites.</p>

                <p>Privacy Policy for our third party online payment system can be found here: <a href="http://support.tillpayments.com/support/solutions/articles/1000283704-till-payments-privacy-policy">http://support.tillpayments.com/support/solutions/articles/1000283704-till-payments-privacy-policy</a></p>
              </div>
            </div>
          </Styled.Grid>
          <hr/>
        </Styled.Wrap>
      </Styled.WrapContent>
    </Container>
  );
};

export default TermsCondition;
