import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Terms from '../components/Terms';
import { I18nextContext } from "gatsby-plugin-react-i18next";

const TermsPage: React.FC = () => {
  const { language } = React.useContext(I18nextContext);
  return (
    <Layout page='terms' locale={language}>
      <SEO title="Terms and Condition" />
      <Terms locale={language}/>
    </Layout>
  );
};

export default TermsPage;
